import { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { Header } from './containers';
import AntConfigProvider from './AntConfigProvider';
import GlobalStyle from './GlobalStyle';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  max-width: 100vw;
`;

export type AppProps = {
  children: ReactNode;
};

export const App: FunctionComponent<AppProps> = ({ children }) => {
  return (
    <AntConfigProvider>
      <Helmet>
        <title>0xTeabag + MetaMask = ❤️</title>
      </Helmet>
      <GlobalStyle />
      <Wrapper>
        <Header />
        {children}
      </Wrapper>
    </AntConfigProvider>
  );
};
