/* eslint-disable @typescript-eslint/consistent-type-definitions */
import React from 'react';
import { ConfigProvider } from 'antd';
import { ConfigProviderProps } from 'antd/es/config-provider';

import { antThemeConfig } from './config/antTheme';

interface IProps extends ConfigProviderProps {
  children: React.ReactNode;
}

function AntConfigProvider({ children, ...props }: IProps) {
  return (
    <ConfigProvider theme={antThemeConfig} {...props}>
      {children}
    </ConfigProvider>
  );
}

export default AntConfigProvider;
