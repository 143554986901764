import { createGlobalStyle } from 'styled-components';

import { black } from './config/colors';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: "Roboto";
    background-color: ${black}
  }
`;

export default GlobalStyle;
