import { theme } from 'antd';
import { ThemeConfig } from 'antd/es/config-provider/context';

import {
  black,
  darkGray,
  darkWhite,
  gray,
  lightGray,
  teal,
  white,
} from './colors';

const padding = 12;

export const antThemeConfig: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorPrimary: teal,
    colorBgBase: black,
    colorBgContainer: darkGray,
    colorFillContent: black,
    colorBgElevated: darkGray,
    colorBgLayout: lightGray,
    colorBgContainerDisabled: black,
    colorPrimaryText: white,
    colorText: darkWhite,
    colorTextLabel: lightGray,
    colorTextBase: lightGray,
    colorTextDescription: lightGray,
    colorTextHeading: lightGray,
    colorTextDisabled: lightGray,
    colorTextPlaceholder: lightGray,
    colorBorderBg: gray,
    colorBorder: gray,
    colorBorderSecondary: gray,
    colorLink: teal,
    colorLinkActive: teal,
    colorLinkHover: teal,
    colorSuccessBg: black,
    colorErrorBg: black,
    colorInfoBg: black,
    colorWarningBg: black,
    colorIcon: teal,
    colorIconHover: teal,
    borderRadius: 0,
    borderRadiusLG: 0,
    borderRadiusSM: 0,
    borderRadiusXS: 0,
    borderRadiusOuter: 0,
    padding,
    paddingMD: padding,
    paddingXL: padding,
    paddingXXS: padding / 4,
    paddingLG: padding,
    paddingSM: padding,
    paddingXS: padding,
    paddingContentHorizontal: padding,
    paddingContentVertical: padding,
  },
};
