/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { create } from 'zustand';

interface IConnectSnapHook extends IConnectSnapState, IConnectSnapMethods {}

interface IConnectSnapState {
  step: number;
  teabag: IStep;
  metamask: IStep;
  snapInstall: IStep;
  snapAuth: IStep;
}

interface IConnectSnapMethods {
  setTeabagLoading(loading: boolean): void;
  setTeabagCompleted(loading: boolean): void;
  setTeabagWarning(type: 'notInstalled' | 'notAuth'): void;
  setMetamaskLoading(loading: boolean): void;
  setMetamaskCompleted(loading: boolean): void;
  setMetamaskWarning(type: 'notInstalled' | 'wrongMetamask'): void;
  setSnapInstallLoading(loading: boolean): void;
  setSnapInstallCompleted(loading: boolean): void;
  setSnapAuthLoading(loading: boolean): void;
  setSnapAuthCompleted(loading: boolean): void;
}

interface IStep {
  loading: boolean;
  completed: boolean;
  error?: string;
  warning?: string;
}

const initialState: IConnectSnapState = {
  step: 0,
  teabag: {
    loading: false,
    completed: false,
  },
  metamask: {
    loading: false,
    completed: false,
  },
  snapInstall: {
    loading: false,
    completed: false,
  },
  snapAuth: {
    loading: false,
    completed: false,
  },
};

export const useConnectSnapStore = create<IConnectSnapHook>((set) => ({
  ...initialState,
  setTeabagLoading: (loading) => {
    set((state) => {
      state.teabag = {
        ...state.teabag,
        loading,
      };

      return { ...state };
    });
  },
  setTeabagCompleted: (completed) => {
    set((state) => {
      state.teabag = {
        ...state.teabag,
        completed,
        loading: false,
        warning: '',
      };

      return { ...state };
    });
    updateStep();
  },
  setTeabagWarning: (message) => {
    set((state) => {
      state.teabag = {
        ...state.teabag,
        warning: message,
      };

      return { ...state };
    });
  },
  setMetamaskLoading: (loading) => {
    set((state) => {
      state.metamask = {
        ...state.metamask,
        loading,
      };

      return { ...state };
    });
  },
  setMetamaskCompleted: (completed) => {
    set((state) => {
      state.metamask = {
        ...state.metamask,
        completed,
        loading: false,
        warning: '',
      };

      return { ...state };
    });
    updateStep();
  },
  setMetamaskWarning: (message) => {
    set((state) => {
      state.metamask = {
        ...state.metamask,
        warning: message,
      };

      return { ...state };
    });
  },
  setSnapInstallLoading: (loading) => {
    set((state) => {
      state.snapInstall = {
        ...state.snapInstall,
        loading,
      };

      return { ...state };
    });
  },
  setSnapInstallCompleted: (completed) => {
    set((state) => {
      state.snapInstall = {
        ...state.snapInstall,
        completed,
        loading: false,
      };

      return { ...state };
    });
    updateStep();
  },
  setSnapAuthLoading: (loading) => {
    set((state) => {
      state.snapAuth = {
        ...state.snapAuth,
        loading,
      };

      return { ...state };
    });
  },
  setSnapAuthCompleted: (completed) => {
    set((state) => {
      state.snapAuth = {
        ...state.snapAuth,
        completed,
        loading: false,
      };

      return { ...state };
    });
    updateStep();
  },
}));

function updateStep() {
  const { teabag, metamask, snapInstall, snapAuth } =
    useConnectSnapStore.getState();

  let step = 0;
  if (teabag.completed) {
    step += 1;
  }

  if (metamask.completed) {
    step += 1;
  }

  if (snapInstall.completed) {
    step += 1;
  }

  if (snapAuth.completed) {
    step += 1;
  }

  useConnectSnapStore.setState({ step });
}
