/* eslint-disable @typescript-eslint/no-use-before-define */
import styled from 'styled-components';

import { ReactComponent as TeabagLogo } from '../assets/teabag_logo.svg';
import { ReactComponent as MetamaskLogo } from '../assets/metamask_logo.svg';
import { METAMASK_HOME, TEABAG_HOME } from '../env';
import { darkGray, gray, white } from '../config/colors';

function Header() {
  return (
    <Container>
      <a href={TEABAG_HOME} target="_blank" rel="noreferrer">
        <TeabagLogo className="logo teabag" />
      </a>
      <span className="plus">+</span>
      <a href={METAMASK_HOME} target="_blank" rel="noreferrer">
        <MetamaskLogo className="logo metamask" />
      </a>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${darkGray};
  border-bottom: 1px solid ${gray};
  min-height: 60px;

  .logo {
    height: 32px;
  }

  .plus {
    margin: 0 20px;
    color: ${white};
    font-size: 30px;
  }
`;

export default Header;
