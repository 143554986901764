/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

import { useConnectSnapStore } from '../hooks';
import { wait } from '../utils';
import { defaultSnapOrigin, isLocalSnap } from '../config';
import { GetSnapsResponse, Snap } from '../types';

function SnapInstallStep() {
  const {
    metamask,
    snapInstall,
    setSnapInstallLoading,
    setSnapInstallCompleted,
  } = useConnectSnapStore();

  const checkSnapInstalled = useCallback(async () => {
    setSnapInstallLoading(true);
    await wait(300);
    try {
      const snap = await getSnap();
      if (snap) {
        return setSnapInstallCompleted(true);
      }
      return setSnapInstallCompleted(false);
    } catch (error) {
      // do nothing
    }
    setSnapInstallLoading(false);
  }, []);

  const installSnap = useCallback(async () => {
    setSnapInstallLoading(true);
    try {
      await connectSnap();
      await checkSnapInstalled();
    } finally {
      setSnapInstallLoading(false);
    }
  }, [checkSnapInstalled]);

  const message = useMemo(() => {
    if (!metamask.completed) {
      return '';
    }

    if (snapInstall.completed) {
      return isLocalSnap ? (
        <Button size="small" onClick={() => installSnap()}>
          Reinstall
        </Button>
      ) : (
        ''
      );
    }

    return (
      <>
        <div className="description">Click the button below:</div>
        <Button size="small" type="primary" onClick={() => installSnap()}>
          Install
        </Button>
      </>
    );
  }, [snapInstall.completed, metamask.completed]);

  useEffect(() => {
    if (metamask.completed) {
      checkSnapInstalled();
    }
  }, [metamask.completed]);

  return <Container>{message}</Container>;
}

const Container = styled.div`
  .description {
    margin-bottom: 8px;
  }
`;

export default SnapInstallStep;

export const connectSnap = async (
  snapId: string = defaultSnapOrigin,
  params: Record<'version' | string, unknown> = {},
) => {
  console.log({
    snapId,
    params,
  });

  await window.ethereum.request({
    method: 'wallet_requestSnaps',
    params: {
      [snapId]: params,
    },
  });
};

export const getSnap = async (version?: string): Promise<Snap | undefined> => {
  try {
    const snaps = await getSnaps();

    return Object.values(snaps).find(
      (snap) =>
        snap.id === defaultSnapOrigin && (!version || snap.version === version),
    );
  } catch (e) {
    console.log('Failed to obtain installed snap', e);
    return undefined;
  }
};

export const getSnaps = async (): Promise<GetSnapsResponse> => {
  return (await window.ethereum.request({
    method: 'wallet_getSnaps',
  })) as unknown as GetSnapsResponse;
};
