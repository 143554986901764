export const blue = '#009bff';
export const violet = '#6500ce';
export const orange = '#f69520';

export const teal = '#00a186';
export const lightTeal = '#00d9b4';
export const darkTeal = '#005c4c';

export const white = '#eeeeee';
export const darkWhite = '#b6b6b6';
export const lightGray = '#7b7b7b';
export const gray = '#1f1f1f';
export const darkGray = '#171717';
export const black = '#111111';
export const purple = '#6500ce';

export const success = '#49aa19';
export const warning = '#d89614';
export const info = '#1668dc';
export const error = '#dc4446';
