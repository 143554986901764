/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
import styled from 'styled-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'antd';

import { useConnectSnapStore } from '../hooks';
import { defaultSnapOrigin } from '../config';
import { TEABAG_EXT_ID } from '../env';
import { wait } from '../utils';
import { teal } from '../config/colors';

function SnapAuthStep() {
  const { snapInstall, snapAuth, setSnapAuthLoading, setSnapAuthCompleted } =
    useConnectSnapStore();
  const [authData, setAuthData] = useState<any>();

  const checkSnapAuthed = useCallback(async () => {
    setSnapAuthLoading(true);
    await wait(300);
    try {
      const auth = await sendGetAuth();
      if (auth) {
        setAuthData(auth);
        return setSnapAuthCompleted(true);
      }
      setAuthData(null);
      return setSnapAuthCompleted(false);
    } catch (error) {
      // do nothing
    }
    setSnapAuthLoading(false);
  }, []);

  const authSnap = useCallback(async () => {
    setSnapAuthLoading(true);
    try {
      await transferAuth();
      await checkSnapAuthed();
    } catch (error) {
      console.error(error);
    } finally {
      setSnapAuthLoading(false);
    }
  }, [checkSnapAuthed]);

  const message = useMemo(() => {
    if (!snapInstall.completed) {
      return '';
    }

    if (snapAuth.completed) {
      return (
        <>
          <div className="connectedAs">
            Connected as <span className="email">{authData?.email}</span>
          </div>
          <Button size="small" onClick={() => authSnap()}>
            Reconnect
          </Button>
        </>
      );
    }

    return (
      <>
        <div className="description">
          Click the button below.
          <br />
          You will be prompted to grant access
          <br />
          to your account.
        </div>
        <Button size="small" type="primary" onClick={() => authSnap()}>
          Connect
        </Button>
      </>
    );
  }, [snapInstall.completed, snapAuth.completed, authData]);

  useEffect(() => {
    if (snapInstall.completed) {
      checkSnapAuthed();
    }
  }, [snapInstall.completed]);

  return <Container>{message}</Container>;
}

const Container = styled.div`
  .description {
    margin-bottom: 8px;
  }

  .connectedAs {
    margin-bottom: 8px;
    .email {
      color: ${teal};
    }
  }
`;

async function sendGetAuth() {
  const res = await window.ethereum.request({
    method: 'wallet_invokeSnap',
    params: { snapId: defaultSnapOrigin, request: { method: 'getAuth' } },
  });

  return res;
}

async function transferAuth() {
  const auth = await getTeabagAuth();
  if (auth) {
    await sendSetAuth(auth);
  }
}

async function getTeabagAuth() {
  return new Promise((resolve, reject) => {
    if (!chrome.runtime) {
      return reject(new Error('Chrome runtime not found'));
    }

    chrome.runtime.sendMessage(
      TEABAG_EXT_ID,
      {
        __type: 'getAuthData',
      },
      (response) => {
        if (chrome.runtime.lastError) {
          return reject(chrome.runtime.lastError);
        }
        return resolve(response);
      },
    );
  });
}

async function sendSetAuth(auth: any) {
  const res = await window.ethereum.request({
    method: 'wallet_invokeSnap',
    params: {
      snapId: defaultSnapOrigin,
      // eslint-disable-next-line prefer-template
      request: { method: 'setAuth', params: auth },
    },
  });

  return res;
}

export default SnapAuthStep;
